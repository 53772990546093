<template>
  <el-drawer
    custom-class="setting-drawer no-select"
    v-model="show"
    :with-header="false"
    direction="rtl"
    :size="410"
    :before-close="closeDrawer"
  >
    <div v-loading="loading">
      <div class="drawer-head">
        <img :src="'/img/setting/' + info.code + '.png'" />
        <div>{{ info.name }}</div>
      </div>
      <el-scrollbar height="calc(100vh - 155px)">
        <el-form label-width="85px">
          <template v-if="info.code === 'wechat'">
            <el-form-item label="服务地址">
              <el-input v-model="form.host" style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item label="AppID">
              <el-input v-model="form.appid" style="width: 160px"></el-input>
            </el-form-item>
            <el-form-item label="AppSecret">
              <el-input v-model="form.secret" style="width: 280px"></el-input>
            </el-form-item>
            <el-form-item label="会话有效期">
              <el-input
                v-model="form.term"
                class="mr-10"
                style="width: 70px"
              ></el-input
              >小时
            </el-form-item>
          </template>
          <template v-else-if="info.code === 'token'">
            <div class="mt-10 mb-10">用户</div>
            <el-form-item label="令牌期限">
              <el-input
                v-model="form.userTerm"
                class="mr-10"
                style="width: 100px"
              ></el-input
              >小时
            </el-form-item>
            <el-form-item label="最大令牌数">
              <el-input v-model="form.userMax" style="width: 80px"></el-input>
            </el-form-item>
            <div class="mb-10">医生</div>
            <el-form-item label="令牌期限">
              <el-input
                v-model="form.doctorTerm"
                class="mr-10"
                style="width: 100px"
              ></el-input
              >小时
            </el-form-item>
            <el-form-item label="长期期限">
              <el-input
                v-model="form.doctorLongTerm"
                class="mr-10"
                style="width: 100px"
              ></el-input
              >小时
            </el-form-item>
            <el-form-item label="最大令牌数">
              <el-input v-model="form.doctorMax" style="width: 80px"></el-input>
            </el-form-item>
          </template>
          <template v-else-if="info.code === 'map'">
            <el-form-item label="百度接口">
              <el-input v-model="form.host" style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item label="地图AK">
              <el-input
                v-model="form.key"
                :rows="2"
                resize="none"
                type="textarea"
                style="width: 250px"
              ></el-input>
            </el-form-item>
            <el-form-item label="地图SN">
              <el-input
                v-model="form.secret"
                :rows="2"
                resize="none"
                type="textarea"
                style="width: 250px"
              ></el-input>
            </el-form-item>
            <el-form-item label="POI筛选">
              <el-input
                v-model="form.poi"
                :rows="3"
                resize="none"
                type="textarea"
                style="width: 250px"
              ></el-input>
            </el-form-item>
          </template>
          <template v-else-if="info.code === 'version'">
            <el-form-item label="服务端版本">
              <el-input v-model="form.service" style="width: 160px"></el-input>
            </el-form-item>
            <el-form-item label="网页端版本">
              <el-input v-model="form.web" style="width: 160px"></el-input>
            </el-form-item>
            <el-form-item label="小程序版本">
              <el-input v-model="form.wechat" style="width: 160px"></el-input>
            </el-form-item>
          </template>
          <template v-else-if="info.code === 'storage'">
            <el-form-item label="MinIO地址">
              <el-input v-model="form.host" style="width: 200px"></el-input>
            </el-form-item>
            <el-form-item label="MinIO账户">
              <el-input v-model="form.key" style="width: 250px"></el-input>
            </el-form-item>
            <el-form-item label="MinIO密钥">
              <el-input
                v-model="form.secret"
                :rows="2"
                resize="none"
                type="textarea"
                style="width: 250px"
              ></el-input>
            </el-form-item>
            <el-form-item label="文件存储桶">
              <el-input v-model="form.bucket" style="width: 100px"></el-input>
            </el-form-item>
          </template>
          <template v-else-if="info.code === 'notice'">
            <el-form-item label="随访通知">
              <el-input
                v-model="form.follow"
                :rows="2"
                resize="none"
                type="textarea"
                style="width: 250px"
              ></el-input>
              <div class="tips">*微信小程序订阅消息</div>
            </el-form-item>
            <el-form-item label="复诊反馈">
              <el-input
                v-model="form['follow-check']"
                :rows="2"
                resize="none"
                type="textarea"
                style="width: 250px"
              ></el-input>
              <div class="tips">*微信小程序订阅消息</div>
            </el-form-item>
          </template>
          <template v-else>
            <div class="form-item" v-for="(item, index) in form" :key="item.id">
              <el-input v-model="item.name" />
              <el-button
                type="danger"
                :icon="deleteIcon"
                class="ml-10"
                :disabled="item.number > 0"
                circle
                @click="removeItem(index)"
              ></el-button>
            </div>
            <div class="form-item">
              <el-input v-model="add" />
              <el-button
                class="ml-10"
                :icon="plusIcon"
                circle
                @click="addItem"
              ></el-button>
            </div>
          </template>
        </el-form>
      </el-scrollbar>
      <div class="mt-15">
        <el-button @click="closeDrawer">关闭</el-button>
        <el-button
          class="float-right"
          type="primary"
          style="width: 100px"
          @click="save"
          >保存</el-button
        >
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { markRaw } from "vue";
import { Delete, Plus } from "@element-plus/icons-vue";
import { system } from "../../plugin/api";

export default {
  name: "SettingDrawer",
  data: () => ({
    show: false,
    loading: false,
    cache: "",
    form: {},
    info: {},
    add: "",
    deleteIcon: markRaw(Delete),
    plusIcon: markRaw(Plus),
  }),
  methods: {
    open(item) {
      this.add = "";
      this.form = {};
      this.info = item;
      this.show = true;
      this.loading = true;
      setTimeout(() => {
        this.queryData();
      }, 500);
    },
    queryData() {
      system
        .querySetting(this.info.code)
        .then((res) => {
          this.form = res.data;
          this.cache = JSON.stringify(res.data);
          this.loading = false;
        })
        .catch(() => {
          this.show = false;
        });
    },
    showWarning(msg) {
      this.$message.warning({
        message: msg,
        center: true,
      });
      return false;
    },
    addItem() {
      if (this.add.trim() !== "") {
        this.form.push({
          id: 0,
          name: this.add,
        });
        this.add = "";
      } else {
        this.showWarning("请先输入内容");
      }
    },
    removeItem(i) {
      this.$confirm(
        "删除后只要未点击右下角的‘保存’就可以通过关闭弹窗重新加入的方式找回选项.",
        "删除选项",
        {
          confirmButtonText: "确认删除",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.form.splice(i, 1);
        this.form = JSON.parse(JSON.stringify(this.form));
      });
    },
    save() {
      let form = JSON.stringify(this.form);
      if (this.cache === form) return this.showWarning("用户信息没有变动");
      else {
        this.$confirm(
          "您对“" + this.info.name + "”进行了编辑,请确认无误再提交",
          "保存变更",
          {
            confirmButtonText: "确认保存",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          this.loading = true;
          if (this.info.type === "type") {
            let oldList = JSON.parse(this.cache);
            let addList = [];
            let removeList = [];
            let updateList = [];
            for (let a in oldList) {
              let exist = false;
              for (let b in this.form) {
                if (
                  oldList[a].id === this.form[b].id ||
                  oldList[a].name === this.form[b].name
                ) {
                  exist = true;
                }
                if (
                  oldList[a].id === this.form[b].id &&
                  oldList[a].name !== this.form[b].name
                ) {
                  updateList.push(this.form[b]);
                  continue;
                }
              }
              if (!exist) removeList.push(oldList[a]);
            }
            for (let a in this.form) {
              if (this.form[a].id === 0) {
                let exist = false;
                for (let b in oldList) {
                  if (this.form[a].name === oldList[b].name) {
                    exist = true;
                    continue;
                  }
                }
                if (!exist) addList.push(this.form[a]);
              }
            }
            form = JSON.stringify({
              add: addList,
              remove: removeList,
              update: updateList,
            });
          }
          system
            .updateSetting(this.info.code, form)
            .then((res) => {
              setTimeout(() => {
                this.loading = false;
                if (res.state) {
                  this.$message.success({
                    message: "保存成功",
                    center: true,
                  });
                  this.queryData();
                } else {
                  this.showWarning(
                    res.message ? res.message : "发生意料之外的错误"
                  );
                }
              }, 500);
            })
            .catch(() => {
              this.loading = false;
              this.$message.error("网络错误");
            });
        });
      }
    },
    closeDrawer() {
      let update = false;

      if (update)
        this.$confirm("关闭窗口后未保存的信息将会丢失", "关闭窗口", {
          confirmButtonText: "确认关闭",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          this.show = false;
        });
      else this.show = false;
    },
  },
};
</script>

<style scoped>
.drawer-head {
  margin-bottom: 10px;
  align-items: center;
  font-weight: bold;
  color: #516161;
  font-size: 18px;
  display: flex;
}

.drawer-head img {
  margin-right: 10px;
  height: 30px;
  width: 30px;
}

.form-item {
  justify-content: space-between;
  background-color: #f4f4f4;
  margin-bottom: 10px;
  align-items: center;
  border-radius: 6px;
  padding: 5px 10px 5px 5px;
  display: flex;
}

.setting-drawer .tips{
  margin-bottom: -15px;
  font-size: 12px;
  color: #999;
}
</style>

<style>
.setting-drawer {
  height: calc(100% - 25px) !important;
  margin: 10px 10px 0 0;
  border-radius: 8px;
  width: 400px;
}
</style>