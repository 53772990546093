<template>
  <div class="container">
    <div class="card pb-0 no-select">
      <el-row :gutter="10">
        <el-col :md="6" :lg="4" :xl="3" v-for="item in list" :key="item.name">
          <div class="setting-item" @click="open(item)">
            <div class="text-center">
              <img :src="'/img/setting/' + item.code + '.png'" />
              <div>{{ item.name }}</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="card mb-0 no-select">
      <el-button plain @click="cleanUser()">踢出所有用户</el-button>
      <el-button plain @click="cleanSocket()">踢出在线会话</el-button>
      <el-button plain @click="cleanCache()">清除缓存数据</el-button>
      <el-button plain disabled>清理垃圾数据</el-button>
      <el-button plain @click="openTest()">网络诊断</el-button>
    </div>
    <setting-drawer ref="settingDrawer" />
    <el-dialog
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      v-model="speed.show"
      :show-close="false"
      title="网络诊断"
      width="300px"
      center
    >
      <div>
        <div class="text-item">
          <span>连通性测试</span>
          <span v-if="speed.t1">{{ speed.t1 }}</span>
          <el-icon v-else class="icon is-loading"><loading /></el-icon>
        </div>
        <div class="text-item">
          <span>上行延迟测试</span>
          <span v-if="speed.t2">{{ speed.t2 }}ms</span>
          <el-icon v-else class="icon is-loading"><loading /></el-icon>
        </div>
        <div class="text-item">
          <span>下行延迟测试</span>
          <span v-if="speed.t3">{{ speed.t3 }}ms</span>
          <el-icon v-else class="icon is-loading"><loading /></el-icon>
        </div>
        <div class="text-item">
          <span>延迟评价</span>
          <span v-if="speed.p1">{{ speed.p1 }}</span>
          <el-icon v-else class="icon is-loading"><loading /></el-icon>
        </div>
        <div class="text-item">
          <span>上行网速测试</span>
          <span v-if="speed.t4">{{ speed.t4 }} M/s</span>
          <el-icon v-else class="icon is-loading"><loading /></el-icon>
        </div>
        <div class="text-item">
          <span>下行网速测试</span>
          <span v-if="speed.t5">{{ speed.t5 }} M/s</span>
          <el-icon v-else class="icon is-loading"><loading /></el-icon>
        </div>
        <div class="text-item">
          <span>速度评价</span>
          <span v-if="speed.p2">{{ speed.p2 }}</span>
          <el-icon v-else class="icon is-loading"><loading /></el-icon>
        </div>
      </div>
      <div class="text-center mt-15">
        <el-button
          type="primary"
          :disabled="!speed.state"
          @click="speed.show = false"
          >关闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SettingDrawer from "../../components/setting/setting-drawer.vue";
import { Loading } from "@element-plus/icons";
import { system } from "../../plugin/api";

export default {
  components: { SettingDrawer, Loading },
  name: "SettingList",
  data: () => ({
    list: [
      {
        name: "微信设置",
        code: "wechat",
        type: "config",
      },
      {
        name: "令牌设置",
        code: "token",
        type: "config",
      },
      {
        name: "地图设置",
        code: "map",
        type: "config",
      },
      {
        name: "存储设置",
        code: "storage",
        type: "storage",
      },
      {
        name: "通知设置",
        code: "notice",
        type: "notice",
      },
      {
        name: "证件设置",
        code: "cert",
        type: "type",
      },
      {
        name: "移植类型",
        code: "transplant",
        type: "type",
      },
      {
        name: "医生科室",
        code: "department",
        type: "type",
      },
      {
        name: "职称设置",
        code: "title",
        type: "type",
      },
      {
        name: "分组设置",
        code: "group",
        type: "type",
      },
      {
        name: "岗位设置",
        code: "post",
        type: "type",
      },
      {
        name: "版本设置",
        code: "version",
        type: "config",
      },
    ],
    speed: {
      show: false,
      state: false,
      t1: "",
      t2: "",
      t3: "",
      t4: "",
      t5: "",
      p1: "",
      p2: "",
    },
  }),
  methods: {
    open(item) {
      this.$refs.settingDrawer.open(item);
    },
    cleanCache() {
      this.$confirm(
        "清除后系统部分页面访问会有一定的加载时间",
        "清除缓存数据",
        {
          confirmButtonText: "确认清除",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        system.cleanCache().then((res) => {
          if (res.state) {
            this.$message.success({
              message: "清除成功",
              center: true,
            });
          } else {
            this.$message.warning({
              message: "清除失败",
              center: true,
            });
          }
        });
      });
    },
    cleanUser() {
      this.$confirm(
        "踢出后所有用户都需要重新登录, 这里的所有用户包括您自己",
        "踢出所有用户",
        {
          confirmButtonText: "确认踢出",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        system.cleanUser().then((res) => {
          if (res.state) {
            this.$message.success({
              message: "已将所有用户踢下线(包括您自己)",
              center: true,
            });
          } else {
            this.$message.warning({
              message: "踢出失败",
              center: true,
            });
          }
        });
      });
    },
    cleanSocket() {
      this.$confirm(
        "踢出后所有会话都需要重新连接",
        "踢出在线会话",
        {
          confirmButtonText: "确认踢出",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        system.cleanSocket().then((res) => {
          if (res.state) {
            this.$message.success({
              message: "已将所有在线会话踢下线",
              center: true,
            });
          } else {
            this.$message.warning({
              message: "踢出失败",
              center: true,
            });
          }
        });
      });
    },
    openTest() {
      let state = 0;
      let start = new Date().getTime();
      this.speed = {
        show: true,
        state: false,
        t1: "",
        t2: "",
        t3: "",
        t4: "",
        t5: "",
        p1: "",
        p2: "",
      };
      system
        .testConnect()
        .then((res) => {
          let date = parseFloat(res.time);
          let end = new Date().getTime();
          setTimeout(() => {
            this.speed.t1 = "成功";
            this.speed.t2 = date - start;
          }, 500);
          setTimeout(() => {
            this.speed.t3 = end - date < 0 ? date - end : end - date;

            if (this.speed.t2 > 800 || this.speed.t3 > 800)
              this.speed.p1 = "极差";
            else if (this.speed.t2 > 600 || this.speed.t3 > 600)
              this.speed.p1 = "差";
            else if (this.speed.t2 > 300 || this.speed.t3 > 300)
              this.speed.p1 = "一般";
            else if (this.speed.t2 > 200 || this.speed.t3 > 200)
              this.speed.p1 = "良好";
            else this.speed.p1 = "优秀";

            state++;
            if (state == 2) this.speed.state = true;
          }, 1300);
        })
        .catch(() => {
          this.speed.t1 = "网络错误";
          this.speed.state = true;
        });
      system
        .testSpeed()
        .then(() => {
          // 获取耗时
          let consume = new Date().getTime() - start;
          let download = parseFloat((3 / consume) * 1000).toFixed(2);
          setTimeout(() => {
            this.speed.t5 = download;
          }, 1000);
          setTimeout(() => {
            this.speed.t4 = parseFloat(download / 10.32).toFixed(2);

            if (this.speed.t5 < 0.1) this.speed.p2 = "极差";
            else if (this.speed.t5 < 0.5) this.speed.p2 = "差";
            else if (this.speed.t5 < 1.0) this.speed.p2 = "一般";
            else if (this.speed.t5 < 5.0) this.speed.p2 = "良好";
            else this.speed.p2 = "优秀";

            state++;
            if (state == 2) this.speed.state = true;
          }, 1500);
        })
        .catch(() => {
          this.speed.t4 = "小于 0.01";
          this.speed.t5 = "小于 0.01";
          this.speed.p2 = "极差";
          this.speed.state = true;
        });
    },
  },
};
</script>

<style scoped>
.setting-item {
  transition: all ease-out 0.3s;
  background-color: #f4f4f4;
  justify-content: center;
  margin-bottom: 10px;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  height: 150px;
  width: 100%;
}

.setting-item:hover {
  background-color: #ecebeb;
}

.setting-item:active {
  background-color: #e2e2e2;
}

.setting-item img {
  padding: 5px;
  height: 60px;
  width: 60px;
}

.text-item {
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding-bottom: 10px;
}

.icon {
  font-size: 20px;
}
</style>